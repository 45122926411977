import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-las-vegas-nevada.png'
import image0 from "../../images/cities/scale-model-of-mysterious-sonic-statue-in-las-vegas-nevada.png"
import image1 from "../../images/cities/scale-model-of-horny-mike-helmet-accessories-in-las-vegas-nevada.png"
import image2 from "../../images/cities/scale-model-of-ameriescape-room-in-las-vegas-nevada.png"
import image3 from "../../images/cities/scale-model-of-strip-gun-club-in-las-vegas-nevada.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Las Vegas'
            state='Nevada'
            image={image}
            lat='36.1699412'
            lon='-115.13982959999998'
            attractions={ [{"name": "Mysterious sonic statue", "vicinity": "Unnamed Road, Las Vegas", "types": ["point_of_interest", "establishment"], "lat": 36.1439232, "lng": -115.1559934}, {"name": "Horny Mike Helmet Accessories", "vicinity": "2712 S Highland Dr, Las Vegas", "types": ["point_of_interest", "establishment"], "lat": 36.140322, "lng": -115.16938600000003}, {"name": "AmeriEscape Room", "vicinity": "210 E Charleston Blvd, Las Vegas", "types": ["point_of_interest", "establishment"], "lat": 36.158502, "lng": -115.151682}, {"name": "Strip Gun Club", "vicinity": "2235 S Las Vegas Blvd, Las Vegas", "types": ["point_of_interest", "establishment"], "lat": 36.1449594, "lng": -115.15630799999997}] }
            attractionImages={ {"Mysterious sonic statue":image0,"Horny Mike Helmet Accessories":image1,"AmeriEscape Room":image2,"Strip Gun Club":image3,} }
       />);
  }
}